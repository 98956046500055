<template>
  <section
    class="banner"
    :class="{ 'on-error': dyRequestCompleted && !banners.length }"
  >
    <div
      v-if="showBanners"
      class="banner__container"
    >
      <!-- Scroll buttons desktop only -->
      <CaIconButton
        v-if="$device.isDesktop && banners.length > 1"
        class="banner__arrow-left"
        aria-label="Voltar para o banner anterior"
        size="medium"
        icon="arrow-left"
        type="inverted"
        shadow
        @click="scrollBannerOnClick('previous')"
      />

      <CaIconButton
        v-if="$device.isDesktop && banners.length > 1"
        class="banner__arrow-right"
        aria-label="Ir para o próximo banner"
        size="medium"
        icon="arrow-right"
        type="inverted"
        shadow
        @click="scrollBannerOnClick('next')"
      />
      <!-- Image list -->
      <div
        ref="carousel"
        class="banner__carousel"
        @scroll.passive="scrollEventHandler()"
      >
        <a
          v-for="(banner, index) in banners"
          :key="banner.payload.data.target"
          :href="banner.payload.data.target"
          target="_blank"
          @click="sendBannerClickEvents(banner, index, $event)"
          @contextmenu="checkContextMenu($event)"
        >
          <picture
            ref="banner"
            class="banner__wrapper"
            :class="{
              highlighted: index === carouselIndex && $device.isDesktop,
            }"
            @mouseenter="stopCarouselAutoPlay()"
            @touchstart="stopCarouselAutoPlay()"
            @mouseleave="startCarouselAutoPlay()"
            @touchend="startCarouselAutoPlay()"
          >
            <source
              :srcset="banner.payload.data.image_new_desktop"
              media="(min-width: 1024px)"
            >
            <img
              class="banner__image"
              alt="banner promocional petlove"
              sizes="(min-width: 1024px) 320px"
              :width="$device.isDesktop ? '1078px' : `${bannerWidth}px`"
              height="428px"
              :loading="index > 1 ? 'lazy' : 'eager'"
              :src="banner.payload.data.image_mobile"
            >
          </picture>
        </a>
      </div>
      <!-- dot list to check banners length -->
      <div
        v-if="banners.length > 1"
        class="dots"
      >
        <span
          v-for="(dot, index) in banners.length"
          :key="dot"
          class="dots__item"
          :class="{ dots__selected: index === carouselIndex }"
        />
      </div>
    </div>
    <!-- placeholder to be rendered on server-side while fetching banners -->
    <div
      v-else-if="!dyRequestCompleted"
      class="skeleton"
    >
      <div
        v-for="index in 3"
        :key="index"
        class="skeleton-animation"
        :class="index === 2 ? 'skeleton__main' : 'skeleton__smaller'"
      />
    </div>
  </section>
</template>

<script>
import { getCookie } from '@petlove/lion/libs/cookies';
import { mapGetters } from 'pinia';

import { dispatchPromotionsGA4 } from '@petlove/lion/libs/ga4-promotions';
import { dispatchPromotionsEvent } from '@petlove/lion/libs/gtm-promotions';

export default {
  setup() {
    const { getDYChoose, reportDYClick } = useDynamicYield();
    const { isLogged } = useAuthStore();

    return {
      getDYChoose,
      reportDYClick,
      isLogged,
    };
  },
  data() {
    return {
      dyBanners: [],
      dyDecisionId: null,
      carouselIndex: 0,
      carouselInterval: null,
      isScrolling: false,
      scrollTimeout: null,
      dyRequestCompleted: false,
      channel: 'alertas-campanhas-banners-ecom',
      prefix: 'Banner Principal',
      data: {
        experiment: 'Home Banner Apps',
        fallback: {
          image:
            'https://www.petlove.com.br/static/uploads/banner_image/image/50561/Principal___Desktop.png',
          link: '"https://www.petlove.com.br/colecao/pet-friday-dos-sonhos-com-descontos-imperdiveis"',
        },
      },
    };
  },
  computed: {
    ...mapGetters(useUsersStore, ['isClubMember', 'getUserPublicIsLoaded']),
    banners() {
      const bannersToShow = this.dyBanners.filter(
        this.shouldShowBannerByProperties,
      );

      return bannersToShow;
    },
    bannerWidth() {
      return window.innerWidth;
    },
    showBanners() {
      return this.banners.length && (!this.isLogged || this.getUserPublicIsLoaded);
    },
  },
  watch: {
    carouselIndex() {
      this.bannerViewEvent(this.carouselIndex);
    },
  },
  async mounted() {
    await this.loadDYBanners();
    this.startCarouselAutoPlay();

    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  beforeUnmount() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange,
    );
  },
  methods: {
    async loadDYBanners() {
      const payload = {
        experiment: this.data.experiment,
        pageType: 'HOMEPAGE',
      };
      try {
        const { choices } = await this.getDYChoose(payload);
        if (!choices?.[0]?.variations?.length) {
          throw new Error('No decision found');
        }
        this.dyDecisionId = choices?.[0]?.decisionId;
        const banners = this.updateVariationsWithFallback(
          choices?.[0]?.variations,
        );
        this.dyBanners = banners.sort(this.sortBanners);
      }
      catch (error) {
        console.error('Dynamic Yield Experiment failed:', error);
        const banners = this.updateVariationsWithFallback();
        this.dyBanners = banners.sort(this.sortBanners);
      }
      finally {
        this.dyRequestCompleted = true;
        this.bannerViewEvent(this.carouselIndex);
      }
    },

    updateVariationsWithFallback(variations) {
      try {
        if (!variations || variations.length === 0) {
          return [
            {
              payload: {
                data: {
                  image_mobile: this.data.fallback?.image,
                  image_new_desktop: this.data.fallback?.image,
                  target: this.data.fallback?.link,
                },
              },
            },
          ];
        }

        return variations.map((variation) => {
          if (!variation || !variation.payload || !variation.payload.data) {
            throw new Error('Variation data is missing or invalid.');
          }

          const { payload } = variation;
          const { data } = payload;
          const { image_new_mobile, image_new_desktop, target } = data;

          const fallbackData = {
            image_new_mobile: image_new_mobile || this.data.fallback?.image,
            image_new_desktop: image_new_desktop || this.data.fallback?.image,
            target: target || this.data.fallback?.link,
          };

          return {
            ...variation,
            payload: {
              ...payload,
              data: {
                ...data,
                ...fallbackData,
              },
            },
          };
        });
      }
      catch (error) {
        this.$honeybadger?.notify(error, {
          name: 'Fallback Error',
          component: 'BannerCarousel',
          pageType: 'HOMEPAGE',
          error: error?.message,
        });
        throw error;
      }
    },

    sortBanners(banner1, banner2) {
      const firstPosition = banner1.payload.data.position;
      const nextPosition = banner2.payload.data.position;
      if (!firstPosition) return 1;
      if (!nextPosition) return -1;
      return firstPosition - nextPosition;
    },
    scroll(direction) {
      this.isScrolling = true;
      const carousel = this.$refs['carousel'] || {};
      const banner = this.$refs['banner']?.at(0) || {};
      const productWidth = banner.offsetWidth;
      if (direction === 'right') {
        carousel.scrollLeft += productWidth;
        return;
      }
      carousel.scrollLeft -= productWidth;
    },
    scrollToPreviousBanner() {
      if (this.carouselIndex === 0) return this.scrollToEnd();
      return this.scroll('left');
    },
    scrollToNextBanner() {
      if (this.carouselIndex === this.banners?.length - 1)
        return this.scrollToBeggining();
      return this.scroll('right');
    },
    scrollBannerOnClick(action) {
      if (this.isScrolling) return;
      if (action === 'next') {
        this.scrollToNextBanner();
      }
      else {
        this.scrollToPreviousBanner();
      }
    },
    scrollToBeggining() {
      const carousel = this.$refs['carousel'] || {};
      carousel.scrollLeft = 0;
    },
    scrollToEnd() {
      const carousel = this.$refs['carousel'] || {};
      carousel.scrollLeft = carousel.scrollWidth;
    },
    startCarouselAutoPlay() {
      if (this.banners?.length <= 1) return;
      this.stopCarouselAutoPlay();
      const interval = 10000;
      this.carouselInterval = setInterval(
        () => this.scrollToNextBanner(),
        interval,
      );
    },
    stopCarouselAutoPlay() {
      if (this.carouselInterval) clearInterval(this.carouselInterval);
    },
    scrollEventHandler() {
      this.stopCarouselAutoPlay();
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
        this.isScrolling = false;
        this.calcActiveBannerIndex();
        this.startCarouselAutoPlay();
      }, 50);
    },
    calcActiveBannerIndex() {
      const margin = this.$device.isDesktop ? 0 : 160;
      const carousel = this.$refs['carousel'] || {};
      const banner = this.$refs['banner']?.at(0) || {};
      const productWidth = banner.offsetWidth;
      const scrollLeft = carousel.scrollLeft;
      const activeBannerIndex = Math.round((scrollLeft - margin) / productWidth);
      this.carouselIndex = activeBannerIndex;
    },
    async sendBannerClickEvents(banner, index, $event) {
      $event?.preventDefault();
      const { target } = banner?.payload?.data || {};
      try {
        const position = index + 1;
        if (window.dataLayer) {
          window.dataLayer?.push({
            event: 'custom_event',
            event_category: 'DY Banner Click',
            event_action: 'Home Carrossel',
            event_label: `${$event.target.currentSrc} | ${position}`,
          });
        }
        if (this.dyDecisionId && banner?.id) {
          await this.reportDYClick(this.dyDecisionId, banner.id);
        }

        const item = {
          id: banner?.payload?.data?.id_ov,
          creative: banner?.payload?.data?.creative_name,
          position: banner?.payload?.data?.position,
          target: banner?.payload?.data?.target,
          type: banner?.payload?.type,
          name: banner?.payload?.data?.bn_name,
          banner_type: banner?.payload?.data?.banner_type,
          supplier: banner?.payload?.data?.supplier,
        };

        dispatchPromotionsEvent('click', [item], 'carrossel-ofertas-I');
        dispatchPromotionsGA4('click', [item], 'carrossel-ofertas-I');
      }
      catch (error) {
        console.error('Error sending banner click events:', error);
      }
      finally {
        if (target) {
          window.location.assign(target);
        }
      }
    },
    checkContextMenu(event) {
      // dont open browser menu when touching and holding
      event?.preventDefault();
      if (!this.$device.isDesktop) {
        event?.preventDefault();
      }
    },
    bannerViewEvent(index) {
      const banner = this.banners[index];
      if (banner) {
        const item = {
          id: banner?.payload?.data?.id_ov,
          creative: banner?.payload?.data?.creative_name,
          position: banner?.payload.data?.position,
          target: banner?.payload?.data?.target,
          name: banner?.payload?.data?.bn_name,
          banner_type: banner?.payload?.data?.banner_type,
          supplier: banner?.payload?.data?.supplier,
        };
        dispatchPromotionsEvent('view', [item], 'carrossel-ofertas-I');
        dispatchPromotionsGA4('view', [item], 'carrossel-ofertas-I');
      }
    },
    shouldShowBannerByProperties({
      payload: {
        data: {
          discount_club_member_exclusive = '',
          discount_club_acquisition_exclusive = '',
        } = {},
      } = {},
    }) {
      const isBannerAcquisitionDiscountClubHidden
        = discount_club_acquisition_exclusive === 'true' && this.isClubMember;

      const isBannerMemberDiscountClubHidden
        = discount_club_member_exclusive === 'true'
          && (!this.isLogged || !this.isClubMember);

      return (
        !isBannerMemberDiscountClubHidden
        && !isBannerAcquisitionDiscountClubHidden
      );
    },
    getEmptyBannerMessage() {
      return `${this.prefix} - Nenhuma recomendação encontrada.
    █  Campanha: \`${this.data.experiment}\`
    ✍️  Assinante: \`${
      !!getCookie('PL_isSubscriber') || !!window.infoPetlove?.subscriber
        ? 'Sim'
        : 'Não'
    }\`
    📍 UF: \`${window.DY?.geoRegionCode || 'Não identificado'}\`
    🆔 DY: \`${window.DY?.dyid}\`
    `;
    },
    handleVisibilityChange() {
      if (document.hidden) {
        this.stopCarouselAutoPlay();
      }
      else {
        this.startCarouselAutoPlay();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-top: space(6);

  @media (min-width: $desktop-breakpoint) {
    min-height: 462px;
    margin-bottom: space(10);
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
  }

  &__arrow-left {
    position: absolute !important;
    z-index: 1;
    left: calc(50% - 580px);
    top: 192px;
  }

  &__arrow-right {
    position: absolute !important;
    z-index: 1;
    right: calc(50% - 580px);
    top: 192px;
  }

  &__carousel {
    display: flex;
    align-items: center;
    gap: space(3);
    overflow-x: auto;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    padding: 0 50%;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: $desktop-breakpoint) {
      gap: unset;
    }
  }

  &__wrapper {
    scroll-snap-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: $desktop-breakpoint) {
      margin-left: -60px;
    }
  }

  &__image {
    border-radius: 16px;
    max-width: 320px;

    @media (max-width: $desktop-breakpoint) {
      height: 340px;
    }

    @media (min-width: $desktop-breakpoint) {
      filter: blur(2px);
      opacity: 0.5;
      transition: all 0.5s ease-in-out;
      max-width: 1078px;
      transform: scale(0.8);
      border-radius: 16px;
    }
  }
}

.dots {
  display: flex;
  gap: space(4);
  margin-top: space(4);

  &__item {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: color(neutral, light);
    transition: background-color 0.3s;
  }
  &__selected {
    background-color: color(primary);
  }

  @media (min-width: $desktop-breakpoint) {
    margin-top: space(7);
  }
}

.on-error {
  min-height: unset;
}

.highlighted {
  img {
    filter: unset;
    opacity: 1;
    transform: unset;
  }
}

.pointer-events-none {
  pointer-events: none;
}

@keyframes skeleton-loading {
  0% {
    background-color: color(neutral, light);
  }
  100% {
    background-color: color(neutral, lightest);
  }
}
.skeleton-animation {
  animation: skeleton-loading 0.5s linear infinite alternate;
}

.skeleton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: space(3);
  overflow: auto;
  min-height: 234px;
  @media (min-width: $desktop-breakpoint) {
    gap: space(10);
    min-height: 464px;
  }

  &__main {
    width: 320px;
    height: 234px;
    border-radius: 16px;

    @media (min-width: $desktop-breakpoint) {
      width: 1078px;
      height: 460px;
    }
  }

  &__smaller {
    width: 320px;
    height: 234px;
    border-radius: 16px;
    flex: 1;

    @media (min-width: $desktop-breakpoint) {
      width: 1078px;
    }
  }
}
</style>
